// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Tabby from "tabbyjs";

const ELEMS   = document.querySelectorAll("[data-tabs]");

if (ELEMS.length > 0) {
    ELEMS.forEach((elem) => {
        const ID = elem.getAttribute("id");

        if (ID) {

            const TABS = new Tabby(`#${ID}`);

            /**
             * When the hash changes, attempt to toggle tab
             */
            window.addEventListener("hashchange", () => {
                if (window.location.hash.match(/^#tab-/) && document.querySelector(window.location.hash)) {
                    TABS.toggle(window.location.hash);
                }
            });

        }

    });
}

